<template>
  <div class="table-responsive" style="display:flex;flex-direction:column">
    <div class="table-responsive" style="flex-grow: 1;">
      <table
        class="table table-striped table-bordered table-hover sticky-header"
        style="flex-grow: 1"
      >
        <thead>
          <tr>
            <th style="width:5%" v-if="showCheckbox" >
              <input
                type="checkbox"
                v-model="isCheckAll"
                @change="checkedAll()"
              />
            </th>
            <th style="width: 5%" v-if="showSerial">序号</th>
            <slot name="thead"></slot>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in gridData"
            @dblclick="dbClick(index, item)"
            @click="oneClick(index, item)"
            :class="{ actives: currSelected == index }"
            :key="paginationData.currentPage + index"
          >
            <td v-if="showCheckbox">
              <input type="checkbox"  @click.stop="()=>{}" v-model="CheckList"  :value="item.ID" />
            </td>
            <td v-if="showSerial">
              {{
                (paginationData.currentPage - 1) * paginationData.itemsPerPage + index + 1
              }}
            </td>
            <slot name="tbody" :data="item">

            </slot>
          </tr>
        </tbody>
      </table>
    </div>
    <div>
      <Pagination :total-itemse='paginationData.totals' :total-page='paginationData.totalPages' :curr-page='paginationData.currentPage' v-bind:page-size='paginationData.itemsPerPage' v-on:pagination-change='PaginationChange'/>
    </div>
  </div>
</template>

<script>
export default {
  name: "Zqtable",
  components: {},
  props: { 
    columns: {
      type: Array,
      default () {
        return []
      }
    },
    gridData: {
      type: Array,
      default () {
        return []
      }
    },
    showPagination: {
      type: Boolean,
      default: true,
    },
    showCheckbox: {
      type: Boolean,
      default: false,
    },
    showSerial: {
      type: Boolean,
      default: false,
    },
    isActivetr: {
      type: Boolean,
      default: true,
    },
    paginationData:{
      type: Object,
      default:()=> {
          return {
                  totals: 100,
                  currentPage: 1,
                  itemsPerPage: 20,
                  totalPages: 5 
                }
      }
    },
  },
  data() {
    return {
      isCheckAll: false,
      CheckList: [],
      currSelected: -1,
    };
  },
  watch: {
    CheckList: function() {
      if (this.gridData.length == this.CheckList.length) {
        this.isCheckAll = true;
      } else {
        this.isCheckAll = false;
      }
      this.$emit("getchecklist", this.CheckList);
    },
    paginationData: function(old,new1){
      console.log("监视1");
      console.log(old);
      console.log(new1);
       console.log("监视2");
    }
  },
  computed: {
    tableStyles() {
      const styles = {};
      if (this.height) {
        styles.height = `${this.height}px`;
      }
      if (this.width) {
        styles.width = `${this.width}px`;
      }
      return styles;
    },    
  },
  methods: {
    checkedAll: function() {
      var _this = this;
      if (_this.isCheckAll) {
        _this.CheckList = [];
        _this.gridData.forEach(function(item) {
          _this.CheckList.push(item.ID);
        });
      } else {
        _this.CheckList = [];
      }
    },
    // 向父组件发送数据
    CallBack: function(data) {
      this.$emit("getgriddata", data);
    },
    PaginationChange: function(index, size) {
      //this.CurrentPage = index;
      //this.pageSize = size;
      this.$emit("paginationChange", index, size);
    },
    dbClick: function(index, rowData) {
      if (this.isActivetr) this.currSelected = index;
      this.$emit("rowdbclick", index, rowData);
    },
    oneClick: function(index, rowData) {
      if (this.isActivetr) this.currSelected = index;
      this.$emit("oneclick", index, rowData);
    },
    
  },
  created() {
    
  },
  mounted() {
    //if (this.showPagination == false) this.pageSize = 100000;
  },

};
</script>

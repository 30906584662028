import { createApp, render } from "vue";
import ConfirmVue from "./src/confirm.vue";

//const ToastsConstructor = a.mount(ToastsVue);

const confirmVueType = ["success", "error", "warning", "info"];
const placementTransition = {
  "top-left": "top-0 start-0",
  "top-center": "top-0 start-50",
  "top-right": "top-0 end-0",
  "bottom-left": "bottom-0 start-50",
  "bottom-center": "bottom-0 start-50",
  "bottom-right": "bottom-0 end-0",
  "center":"bottom-50 start-50"
};
const instances = [];
let instance;
let seed = 1;
let zindexSeed = 102000;

const Confirm = (options) => {
 
 return  new Promise((resolve, reject) => {
    // 点击确定按钮
   
    let mountNode = document.querySelector(`.Confirm`);
    if (!mountNode) {
      mountNode = document.createElement("div");
      mountNode.style.zIndex = zindexSeed;
      mountNode.classList.add("Confirm")
      document.body.appendChild(mountNode);
     
    }
    else{
      let childmountNode = document.createElement("div");    
      mountNode.appendChild(childmountNode)
    }
    
    const confirmInstance = createApp(ConfirmVue,
      options
    );
    confirmInstance._props.closed = () => {
      confirmInstance.unmount()
      if(mountNode.childNodes.length==0)
      {
        document.body.removeChild(mountNode)
      }
    }
    confirmInstance._props.cancelCallback = () => {
      confirmInstance.unmount()
      if(mountNode.childNodes.length==0)
      {
        document.body.removeChild(mountNode)
      }
      reject()
    }
    confirmInstance._props.submitCallback = () => {
      confirmInstance.unmount()
      if(mountNode.childNodes.length==0)
      {
        document.body.removeChild(mountNode)
      }
      resolve()
    }
    confirmInstance.mount(mountNode);
    //return confirmInstance.vm
  })
};
Confirm.pname="Confirm";
Confirm.propertie="$Confirm";
export default Confirm;


<template>
  <ul class="nav" :class="isFirstlevel ? '' : 'flex-column sub-menu'">
    <template v-for="item in menuData" :key="item.index">
      <li class="nav-item nav-category" v-if="item.Type == 0">
        <span class="nav-link">{{ item.Name }}</span>
      </li>
      <li
        v-else-if="item.Type == 2"
        class="nav-item"
        :class="isFirstlevel ? 'menu-items' : ''"
        @mouseover="sidebarShowsub"
        @mouseleave="sidebarHidesub"
      >
        <a class="nav-link" v-on:click.stop="navClick($event,item)">
          <span class="menu-icon"> <i class="mdi mdi-speedometer"></i> </span>
          <span :class="isFirstlevel ? 'menu-title' : ''">{{ item.Name }}</span>
        </a>
      </li>
      <li
        v-else
        class="nav-item"
        :class="{'menu-items':isFirstlevel,active:currFatherID==item.ID}"
        @mouseover="sidebarShowsub"
        @mouseleave="sidebarHidesub"
      >
        <a
          class="nav-link"
          data-bs-toggle="collapse"
          @click.stop="
            showChild == item.ID ? (showChild = '') : (showChild = item.ID)
          "
        >
          <span class="menu-icon">
            <i class="mdi mdi-laptop"></i>
          </span>
          <span :class="isFirstlevel ? 'menu-title' : ''">{{ item.Name }}</span>
          <i class="menu-arrow"></i>
        </a>
        <transition name="sub-menu">
          <div class="collapse" :class="showChild == item.ID ? 'show' : ''">
            <slot>
              <Submenu
                :menuData="item.childrenMoudel" :fatherMenu='item.ID'
                :isFirstlevel="false"  v-on:navClick='navClick1'
              ></Submenu>
            </slot>
          </div>
        </transition>
      </li>
    </template>
  </ul>
</template>

<script>
export default {
  name: "Submenu",
  props: {
    menuData: {
      type: Array,
    },
    fatherMenu:{
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    opened: {
      type: Boolean,
      default: false,
    },
    isFirstlevel: {
      type: Boolean,
      default: true,
    },
    showChild: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      active: false,
      currFatherID:""
    };
  },
  computed: {},
  watch: {},
  methods: {
    navClick(e, data) {
      this.$emit("navClick", this.fatherMenu,data);
      
    },
    navClick1(father,data){
      this.currFatherID=father;      
      this.$emit("navClick", this.fatherMenu,data);
    },
    collapseSidebaritem(e) {
      if (e.currentTarget.dataset.toggle == "collapse") {
        var brothers = e.currentTarget.parentNode.parentNode.children;
        brothers.forEach((element) => {
          element.children.forEach((el) => {
            if (el.tagName == "A") {
              if (
                el.nextElementSibling != null &&
                !el.nextElementSibling.classList.contains("collapse")
              ) {
                el.nextElementSibling.classList.add("collapse");
                el.dataset.toggle = "collapse";
              }
            }
          });
        });
        e.currentTarget.dataset.toggle = "";
        e.currentTarget.nextElementSibling.classList.remove("collapse");
      } else {
        e.currentTarget.dataset.toggle = "collapse";
        e.currentTarget.nextElementSibling.classList.add("collapse");
      }
    },
    sidebarShowsub(e) {
      var isIconOnly =
        document
          .getElementsByTagName("body")[0]
          .getAttribute("class")
          .includes("sidebar-icon-only") &&
        !e.currentTarget.className.includes("hover-open");
      if (isIconOnly) {
        e.currentTarget.className = e.currentTarget.className + " hover-open";
      }
    },
    sidebarHidesub(e) {
      e.currentTarget.className = e.currentTarget.className.replace(
        " hover-open",
        ""
      );
    },
  },
  mounted() {},
};
</script>
<style scoped>
.sub-menu-enter-active,
.sub-menu-leave-active {
  transition: all 0.15s ease;
  height: 100%;
}
.sub-menu-enter,
.sub-menu-leave-to {
  transition: all 0.15s ease;
  height: 0px;
}
</style>

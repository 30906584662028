<template>
  <div
    :class="['toast fadeInLeft ', isShow]"
    role="alert"
    aria-live="assertive"
    aria-atomic="true"
    :data-bs-autohide="autohide"
    :data-bs-animation="animation"
    :data-bs-delay="5"
  >
    <div v-if="!concise" :class="['toast-header','toast-'+type]">
      <span class="rounded me-2 fs-5 "
        ><i class="bi bi-chat-text"></i
      ></span>
      <strong class="me-auto">{{ title }}</strong>
      <small>{{ subtitle }}</small>
      <button type="button" class="btn-close" v-on:click="close"></button>
    </div>
    <div v-if="!concise" :class="['toast-body','toast-'+type]">
      {{ message }}
    </div>
    <div v-if="concise" :class="['d-flex','toast-'+type]">
      <div class="toast-body"> {{ message }}</div>
      <!--<button
        type="button"
        class="btn-close me-2 m-auto"
        v-on:click="close"
      >{{placement}}</button> -->
    </div>
  </div>
</template>

<script>

//import toast from 'bootstrap/js/src/toast.js'
export default {
  name: "Toasts",
  props: {
    concise:{
       type: Boolean,
        default: false,
    },
    type:{
        type: String,
        default: "Primary",
    },
    title: {
      type: String,
      default: "",
    },
    message: {
      type: String,
      default: "",
    },
    closed: {
      type: Function,
      default: () => {},
    },
    delay: {
      type: Number,
      default: 10000,
    },
    placement: {
      type: String,
      default: "确认",
    },
  },
  emits: ["closeEvent"],
  data() {
    return {
      isShow: "show",
      subtitle: "",
      autohide: true,
      animation: true,
      timer: null,
    };
  },

  watch: {},
  methods: {
    close() {
      this.closed();
    },
  },
  setup(props, { emit }) {
    const close1 = function () {
      props.closed();
    };
    return {
      close1,
    };
  },
  mounted() {
    //var a=new toast(document.getElementById("fff"));
    //a.show();
    this.timer = setTimeout(() => {
      this.close();
    }, this.delay);
    //this.$refs.toast.addEventListener('hide.bs.toast', function () {
    //  console.log("dfdfdf");
    //  })
  },
};
</script>

<style lang="scss" scoped>

.toast-Primary{
  color:#434a54;
}
.toast-Success{
  background: #00d25b; 
  color:#f6f8fa;  
  ::deep(.toast-header){
    background-color: #00d25b;
  }
}
.toast-Info{
  background: #8f5fe8;  
  color:#f6f8fa;
  ::deep(.toast-header){
    background-color: #8f5fe8;
  }
}
.toast-Warning{
  background: #ffab00; 
  color:#f6f8fa;
  ::deep(.toast-header){
     background-color: #ffab00;
  }
}
.toast-Danger{
  background: #fc424a;  
  color:#f6f8fa;
  ::deep(.toast-header){
    background-color:  #fc424a;
  }
}
.toast-Light{
  background: #e8eff4;  
  color:#434a54;
  ::deep(.toast-header){
    background-color: #e8eff4;
  }
}
.toast-Dark{
  background: #0f172a;  
  color:#f6f8fa;
  ::deep(.toast-header){
    background-color: #0f172a;
  }
}
</style>

import axios from "axios"
import Vue from "vue"
import Toasts from '../components/toasts'


//axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8';
axios.defaults.timeout = 60000;

const request = axios.create();

// 响应拦截
request.interceptors.response.use(
    response => {
        // response.data.code为后端定义的响应状态码，200为成功
        if (!response.data || response.data.code <0) {
            // response.data.message为后端定义的响应消息
            Toasts({
                        title: "请求出错",
                        message: response.data.message,
                        placement: "bottom-right",
                        concise: false,
                        delay: 5000,
                        type:"Warning"
                    });
            return Promise.reject(response.data)
        }
        return Promise.resolve(response.data.message)
    },
    error => {
        console.log(error);
        Toasts({
            title: "服务器访问出错",
            message: '系统出错啦',
            placement: "bottom-right",
            concise: false,
            delay: 5000,
            type:"Danger"
        });
        return Promise.reject(error)
    },

)
request.pname="ARequest";
request.propertie="$ARequest";
export default request;

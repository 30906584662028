<template>
  <transition name="fade">
    <div class="loader">
      <div class="loader-mask" :style="style"></div>
      <div class="container">
        <div :class="['spinner-border','loading-' + type]" :style="style" role="status">
          <span class="sr-only"></span>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
//import toast from 'bootstrap/js/src/toast.js'
export default {
  name: "Confirm",
  props: {
    type: {
      type: String,
      default: "Primary",
    },
    message: {
      type: String,
      default: "",
    },
     zindex: {
      type: Number,
      default: "599999",
    }
  },
  emits: ["closeEvent"],
  data() {
    return {
      isShow: "show",
      subtitle: "",
      autohide: true,
      animation: true,
      timer: null,
      modal: null,
      style:{'z-index':this.zindex},
    };
  },

  watch: {},
  methods: {
   Close(){
      this.modal.hide()
    },
  },
  mounted() {

  },
  beforeUnmount() {

  },
};
</script>

<style lang="scss" scoped>
.loader {
  width: 100%;
  height: 100%;
}
.loader-mask {
  position: fixed;
  z-index: 9;
  left: 0;
  right: 0;
  top: 0;
  background: #000000;
  opacity: 0.4;
  width: 100%;
  height: 100%;
}

.spinner-border {
  position: absolute;
  top: 50%;
  left: 50%;
}
.loading-Primary {
  color: #0f172a;
}
.loading-Success {
  color: #00d25b;
}
.loading-Info {
  color: #8f5fe8;
}
.loading-Warning {
  color: #ffab00;
}
.loading-Danger {
  color: #fc424a;
}
.loading-Light {
  color: #e8eff4;
}
.loading-Dark {
  color: #0f172a;
  }
</style>

<template>
  <teleport to="body">
    <transition name="fade">
      <div ref="modal"
        class="modal fade " 
        tabindex="-1"
        aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false" 
      >
        <div class="modal-dialog" :class="[width]" modal-dialog-scrollable modal-dialog-centered>
          <div class="modal-content">
            <div class="modal-header">
              <slot name="header"></slot>
              <h5 class="modal-title">{{ title }}</h5>
              <button
                @click="CloseModel"
                type="button"
                class="btn-close btn-close-white"
                data-bs-dismiss="modal"
                aria-label="Close"
                tabindex="0"
              ></button>
            </div>
            <div class="modal-body">
              <slot name="content"></slot>
            </div>
            <div class="modal-footer">
              <slot name="footer"></slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script>
import  'bootstrap'
export default {
  name: "Modal",
  props: {
    title: {
      type: String,
      default: null,
    },
     width: {
      type: String,
      default: 'modal-xl', //modal-lg  modal-sm
    },
  },
  data() {
    return {
      modal: null
    };
  },
  watch: {
  },
  mounted() {
    this.modal = new bootstrap.Modal(this.$refs.modal)
  },
  computed: {},
  methods: {
    CloseModel(){
      this.modal.hide()
    },
    ShowModel(){
      this.modal.show();
    }
  },
};
</script>
<style scoped>
.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(13, 110, 253, 0.25);
  opacity: 1;
}
</style>

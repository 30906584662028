<template>
  <transition name="fade">
    <div
      ref="modal"
      class="modal fade"
      tabindex="-1"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
    <div :class="['modal-dialog modal-dialog-centered',width]" >
          <div :class="['modal-content','modal-' + type]">
            <div :class="['modal-header','modal-' + type]">
              <slot name="header"></slot>
              <h5 class="modal-title">{{ title }}</h5>
             
            </div>
            <div class="modal-body">
              {{ message }}
            </div>
            <div class="modal-footer">
               <button
                v-on:click="submitCallback"
                type="button"
                class="btn btn-primary btn-sm"
              >
                {{ confirmText }}
              </button>
              <button
                v-on:click="cancelCallback"
                type="button"
                class="btn btn-secondary btn-sm"
              >
                {{ cancelText }}
              </button>
            </div>
          </div>
        </div>

    </div>
  </transition>
</template>

<script>
//import toast from 'bootstrap/js/src/toast.js'
export default {
  name: "Confirm",
  props: {
    concise: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "Primary",
    },
    title: {
      type: String,
      default: "请确认操作",
    },
    message: {
      type: String,
      default: "",
    },
    closed: {
      type: Function,
      default: () => {},
    },
    delay: {
      type: Number,
      default: 10000,
    },
    confirmText: {
      type: String,
      default: "确认",
    },
    cancelText: {
      type: String,
      default: "取消",
    },
    submitCallback: {
      type: Function,
    },
    cancelCallback: {
      type: Function,
    },
  },
  emits: ["closeEvent"],
  data() {
    return {
      isShow: "show",
      subtitle: "",
      autohide: true,
      animation: true,
      timer: null,
       modal: null
    };
  },

  watch: {},
  methods: {
    close() {
      
    },
  },
  setup(props, { emit }) {
    const close1 = function () {
      props.closed();
    };
    return {
      close1,
    };
  },
  mounted() {
    //this.timer = setTimeout(() => {
    //  this.close();
    //}, this.delay);
     this.modal = new bootstrap.Modal(this.$refs.modal);
      this.modal.show();
  },
   beforeUnmount() {
     this.modal.hide();
    }
};
</script>

<style lang="scss" scoped>
.modal-Primary {
  color: #434a54;
}
.modal-Success {
  background: #00d25b;
  color: #f6f8fa;
  :deep(.modal-header) {
    background-color: #00d25b;
  }
}
.modal-Info {
  background: #8f5fe8;
  color: #f6f8fa;
 :deep(.modal-header) {
    background-color: #8f5fe8;
  }
}
.modal-Warning {
  background: #ffab00;
  color: #f6f8fa;
  :deep(.modal-header) {
    background-color: #ffab00;
  }
}
.modal-Danger {
  background: #fc424a;
  color: #f6f8fa;
  :deep(.modal-header) {
    background-color: #fc424a;
  }
}
.modal-Light {
  background: #e8eff4;
  color: #434a54;
  :deep(.modal-header) {
    background-color: #e8eff4;
  }
}
.modal-Dark {
  background: #0f172a;
  color: #f6f8fa;
  :deep(.modal-header) {
    background-color: #0f172a;
  }
}
</style>

import { createApp, render } from "vue";
import ToastsVue from "./src/toasts.vue";

//const ToastsConstructor = a.mount(ToastsVue);

const toastsType = ["success", "error", "warning", "info"];
const placementTransition = {
  "top-left": "top-0 start-0",
  "top-center": "top-0 start-50",
  "top-right": "top-0 end-0",
  "bottom-left": "bottom-0 start-50",
  "bottom-center": "bottom-0 start-50",
  "bottom-right": "bottom-0 end-0",
};
const instances = [];
let instance;
let seed = 1;
let zindexSeed = 101000;

const Toasts = (options) => {
  const messageInstance = createApp(ToastsVue,
    options
  );
  let mountNode = document.querySelector(`.toast-container.${placementTransition[options.placement].split(' ')[0]}.${placementTransition[options.placement].split(' ')[1]}`);
  if (!mountNode) {
    mountNode = document.createElement("div");
    mountNode.style.zIndex = zindexSeed;
    mountNode.classList.add("toast-container")
    mountNode.classList.add("position-absolute")
    mountNode.classList.add(`${placementTransition[options.placement].split(' ')[0]}`);
    mountNode.classList.add(`${placementTransition[options.placement].split(' ')[1]}`);
    document.body.appendChild(mountNode);    
    messageInstance._props.closed = () => {
      messageInstance.unmount()
      if(mountNode.childNodes.length==0)
      {
        document.body.removeChild(mountNode)
      }
    }
    messageInstance.mount(mountNode);
  }
  else{
    let childmountNode = document.createElement("div");    
    messageInstance._props.closed = () => {
      messageInstance.unmount()
      mountNode.removeChild(childmountNode)
      if(mountNode.childNodes.length==0)
      {
        document.body.removeChild(mountNode)
      }
    }
    mountNode.appendChild(childmountNode)
    messageInstance.mount(childmountNode);
  }
  
  
  //mountNode.appendChild("")
  


  //render(messageInstance,mountNode);



  // 指定时间内，移除message组件
  return messageInstance.vm
};
Toasts.pname="Toast";
Toasts.propertie="$Toast";
export default Toasts;


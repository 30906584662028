<template>
  <div
    class="
      sidebar-brand-wrapper
      d-none d-lg-flex
      align-items-center
      justify-content-center
      fixed-top
    "
  >
    <a class="sidebar-brand brand-logo" href="index.html"
      ><img :src="logoPath+title[0]"  />{{ title[1] }}</a
    >
    <a class="sidebar-brand brand-logo-mini" href="index.html"
      ><img  :src="logoPath+title[0]" alt="logo"
    /></a>
  </div>
  <Submenu :menuData="menuData" v-on:navClick='navClick' />
</template>

<script>
import Submenu from "@/components/menu/src/submenu.vue";
export default {
  name: "SideBarMenu",
  components: {
    Submenu,
  },
  props: {
    title: {
      type: Array,
      default: () => ["logo.png", "TMS管理"],
    },
    logoPath: {
      type: Array,
      default: "/src/assets/images/logo/",
    },
    menuData: {
      type: Array,
    },
    mode: {
      type: String,
      default: "inline",
      validator: (val) =>
        ["inline", "horizontal", "vertical"].indexOf(val) > -1,
    },
    theme: {
      type: String,
      default: "light",
      validator: (val) => ["light", "dark"].indexOf(val) > -1,
    },
    activeName: [String, Number],
    inlineCollapsed: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: "240px",
    },
    router: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentActiveName: this.activeName,
      logo: "",
    };
  },
  computed: {},
  watch: {},
  methods: {
  navClick(father,data){
       this.$emit("navClick", data);
    },
  },
  mounted() {},
};
</script>

import { App } from '@vue/runtime-core'
import axios from "axios"

import  Toast  from '../src/components/toasts/index'
import  Confirm  from '../src/components/confirm/index'
import  loading  from '../src/components/loading/index'
import  ARequest  from '../src/tools/request'

import  Pagination  from '../src/components/pagination/src/pagination.vue'
import  Table  from '../src/components/table/src/table.vue'
import  Menu  from '../src/components/menu/src/menu.vue'
import  Modal  from '../src/components/modal/src/modal.vue'

import "bootstrap-icons/font/bootstrap-icons.css";

import  '@/assets/scss/style.scss'


const components = [Pagination,Table,Menu,Modal];
const withProperties = [Toast,Confirm,loading,ARequest];
const install = function (App, options) {
    App.config.globalProperties['$axios'] = axios;
    App.provide('$axios', axios)
    components.forEach((component) => {
        App.component(component.name, component);   
    });
    withProperties.forEach((withPropertie)=>{
      App.config.globalProperties[withPropertie.propertie] = withPropertie
      App.provide(withPropertie.pname, withPropertie)
    })
};
export default { install, components };




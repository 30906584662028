<template>
  <div class="card"  style="margin-bottom:2px">
    <div class="card-footer d-flex align-items-center p-1 mt-1">
      <div class="col-sm-5 d-flex align-items-center">
        <select
          class="form-select pagination-selectitems"
          v-model="PerPageSize"
        >
          <option v-for="(item, index) in pageSizes" :value="item" :key="index">
            {{ item }}条/页
          </option>
        </select>
        <p class="m-0 text-muted mx-1">
          当前第 <span>{{ currPage }}</span> 页
          <span>{{ totalItemse }}条记录</span>
        </p>
      </div>

      <div class="col-sm-7 align-items-center">
        <nav aria-label="Page navigation align-items-center">
          <ul class="pagination justify-content-end mb-1">
            <li
              v-bind:class="currPage == 1 ? 'disabled' : ''"
              class="page-item"
            >
              <a class="page-link"   tabindex="-1" aria-disabled="true" v-on:click="sizeChange(1)"
                >第一页</a
              >
            </li>
            <li
              v-bind:class="currPage < 2 ? 'disabled' : ''"
              class="page-item "
            >
              <a class="page-link" v-on:click="currPage<2 ? null : sizeChange(currPage-1)" tabindex="-1" aria-disabled="true"
                >上一页</a
              >
            </li>
            <li
              class="page-item"
              v-bind:class="item == currPage ? 'active' : ''"
              v-for="(item, index) in pages"
              :key="index"
            >
              <a class="page-link" v-on:click="sizeChange(item)">{{ item }} </a>
            </li>
            <li
              v-bind:class="currPage < totalPage ? '' : 'disabled'"
              class="page-item"
            >
              <a class="page-link" v-on:click="currPage<totalPage ? sizeChange(currPage+1) : null  ">下一页</a>
            </li>
            <li
              v-bind:class="currPage == totalPage ? 'disabled' : ''"
              class="page-item"
            >
              <a class="page-link" v-on:click="currPage == totalPage ? null : sizeChange(totalPage)">最后一页</a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    pageSizes: {
      type: Array,
      default() {
        return [10, 20, 30, 50];
      },
    },
    pageSize: {
      type: Number,
      default: 20,
    },
    currPage: {
      type: Number,
      default: 1,
    },
    totalItemse: {
      type: Number,
      default: 1,
    },
    totalPage: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      PerPageSize: this.pageSize,
      pages: [],
    };
  },
  watch: {
    PerPageSize: function () {
      this.sizeChange(1);
    },
    currPage: function () {
      this.initPage();
    },
    totalPage: function () {
      this.initPage();
    },
  },
  computed: {
    
  },
  methods: {
    sizeChange: function (index) {
      this.$emit("paginationChange", index, this.PerPageSize);
    },
    initPage: function () {
      if (this.currPage % 5 == 0) return;
      var k = parseInt(this.currPage / 5);
      var m = 5;

      this.pages = [];
      for (
        var index = k * 5 + 1;
        index <= (k * 5 + m < this.totalPage ? k * 5 + m : this.totalPage);
        index++
      ) {
        this.pages.push(index);
      }
    },
  },
};
</script>


import { createApp, render, shallowReactive } from "vue";
import loadingVue from "./src/loading.vue";

//const ToastsConstructor = a.mount(ToastsVue);

const confirmVueType = ["success", "error", "warning", "info"];
const placementTransition = {
  "top-left": "top-0 start-0",
  "top-center": "top-0 start-50",
  "top-right": "top-0 end-0",
  "bottom-left": "bottom-0 start-50",
  "bottom-center": "bottom-0 start-50",
  "bottom-right": "bottom-0 end-0",
  "center":"bottom-50 start-50"
};
const instances = [];
let instance;
let seed = 1;
let zindexSeed = 202001;

const loading = (options) => {
  if(!options)
    {
      options={};
    }
    options.zindex=zindexSeed;
  const loadingInstance = createApp(loadingVue,
    options
  );
  let mountNode = document.querySelector(`.loading`);
  
    if (!mountNode) {
      mountNode = document.createElement("div");
      mountNode.style.zIndex = zindexSeed;
      mountNode.classList.add("loading")
      document.body.appendChild(mountNode);
     
    };
    loadingInstance.mount(mountNode);
   return {
     close(){
      loadingInstance.unmount();
     }
   }

};
loading.pname="loading";
loading.propertie="$loading";
export default loading;

